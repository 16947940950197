import React from 'react';
import { graphql } from 'gatsby';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import FormContainer from 'components/FormContainer';
import PageWrapper from 'components/PageWrapper';
import PartneringRequestForm from 'components/PartneringRequestForm';

import Hero from 'storyblok/components/Hero';

const PartnerWithUs = ({ data }) => {
	const pageTitle = 'Partner With Us';
	const pageSubtitle = `Join our network of top restaurants in ${makeCommaSeparatedList(
		data.allSporkLocation.nodes.map(({ name }) => name)
	)}`;

	return (
		<PageWrapper
			title={pageTitle}
			description={pageSubtitle}
			displayNewsletterSignup={false}
			ogImage={{
				alt: pageTitle,
				src: `${data.site.siteMetadata.url}${data.headerImage.childImageSharp.fixed.src}`,
			}}
			postContent={
				<>
					<Hero
						blok={{
							backgroundImage:
								data.headerImage.childImageSharp.fluid,
							headline: pageTitle,
							height: 'medium',
							subHeadline: pageSubtitle,
						}}
					/>
					<FormContainer>
						<PartneringRequestForm />
					</FormContainer>
				</>
			}
		/>
	);
};

export const query = graphql`
	query GetHero {
		headerImage: file(relativePath: { eq: "partner-with-us-hero.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1500) {
					...GatsbyImageSharpFluid_tracedSVG
				}
				fixed(height: 630, width: 1200) {
					src
				}
			}
		}
		site {
			siteMetadata {
				title
				url
			}
		}
		allSporkLocation {
			nodes {
				id
				name
			}
		}
	}
`;

export default PartnerWithUs;
